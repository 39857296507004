
import 'normalize.css';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import { HelmetProvider } from 'react-helmet-async';
// import { FormattedMessage } from 'react-intl';
import { IntlManager } from './IntlManager';
import { useTypedSelector } from './stateStore'


import NavbarComponent from "./components/Blocks/Navbar_v2";
import FooterComponent from "./components/Blocks/Footer";


// Landing views
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import About from "./pages/About";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import Demo from "./pages/Demo";
import CaseStudy from "./pages/CaseStudy";
import NotFound404 from "./pages/404";

import Blog from "./pages/Blogs/blog";
import BlogDetail from "./pages/Blogs/detailblog";

import Manufacture from './pages/Solutions/manufacture';
import Inventory from './pages/Solutions/inventory';
import CRM from './pages/Solutions/crm';
import Accounting from './pages/Solutions/accounting';


const App = () => {
  const showContent = useTypedSelector((state) => state.TrueFalse.currentValue)
  const currentLocale = useTypedSelector((state) => state.Locale.currentLocale)
  return (
    <HelmetProvider>
      <BrowserRouter>
        <IntlManager locale={currentLocale}>
          <NavbarComponent/>
            {
              showContent && (
                <>
                  <main className='pt-16'> 
                    <Routes>

                      <Route path='/' element={<Landing />} />
                      <Route path='/login' element={<Login />} />
                      <Route path='/about' element={<About />} />
                      <Route path='/products' element={<Products />} />
                      <Route path='/contact' element={<Contact />} />
                      <Route path='/demo' element={<Demo />} />
                      <Route path='/casestudy' element={<CaseStudy />} />
      
                      <Route path='/solution/manufacture' element={<Manufacture />} />
                      <Route path='/solution/crm' element={<CRM />} />
                      <Route path='/solution/inventory' element={<Inventory />} />
                      <Route path='/solution/accounting' element={<Accounting />} />

                      <Route path='/blog' element={<Blog />} />
                      <Route path="/blog/:slug/:documentId" element={<BlogDetail />} />

                      <Route path='*' element={<NotFound404 />} />

                    </Routes>
                  </main>
                  <FooterComponent/>
                </>
              )
            }
        </IntlManager>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
import React, { useState } from "react";
import { Header_v1 } from "../../components/Blocks/Header";
import { CTA_v2 } from '../../components/Blocks/CTA';
import { Icon } from '@iconify-icon/react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import header_solution_inventory from '../../assets/header/header_solution_inventory.webp';

import replenishment from '../../assets/product/inventory/replenishment.webp';


function Inventory() {
    const [tab, setTab] = useState(1);

    const changeTab = (tab: number) => {
        setTab(tab)
    }

    return (
        <>
            <Helmet>
                <title>Inventory Solution | DSN</title>
                <meta name="description" content="Selamat datang di website resmi PT.Delta Solusi Nusantara." />
                <meta name="keywords" content="Odoo inventory management, stock management software, supply chain optimization, warehouse management, inventory tracking" />
            </Helmet>
            <Header_v1 tag_1="inventory_solution_tag_1" tag_2="inventory_solution_tag_2">
                <img src={header_solution_inventory} />
            </Header_v1>
            <section className="px-5 lg:px-20 pt-24">
                <h1 className="display-sm lg:display-lg font-semibold text-left"><FormattedMessage id="inventory_solution_tag_3" /></h1>
                <p className="text-medium lg:text-extralarge font-medium w-full lg:w-3/5 text-left mb-10 lg:mb-16"><FormattedMessage id="inventory_solution_tag_4" /></p>
            
                {/* mobile */}
                <div className="flex flex-col gap-y-16 block lg:hidden">
                    <div>
                        <img src={replenishment} width={'100%'} className="mb-10 lg:mb-16"/>
                        <div className="flex flex-col justify-between display-xs font-semibold ">
                            <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                                <Icon icon="ic:round-document-scanner" />
                            </div>
                            <h3 className="mb-2"><FormattedMessage id="accounting_feature_1" /></h3>
                            <p className={"text-large font-medium " + (tab !== 1 ? 'hidden' : '')}>
                                <FormattedMessage id="inventory_feature_desc_1" />
                            </p>
                        </div>
                        </div>
                    <div>
                        <img src={replenishment} width={'100%'} className="mb-10 lg:mb-16"/>
                        <div className="flex flex-col justify-between display-xs font-semibold ">
                            <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                                <Icon icon="ic:round-aod" />
                            </div>
                            <h3 className="mb-2"><FormattedMessage id="accounting_feature_1" /></h3>
                            <p className={"text-large font-medium " + (tab !== 1 ? 'hidden' : '')}>
                                <FormattedMessage id="inventory_feature_desc_1" />
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={replenishment} width={'100%'} className="mb-10 lg:mb-16"/>
                        <div className="flex flex-col justify-between display-xs font-semibold ">
                            <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                                <Icon icon="ic:round-auto-awesome" />
                            </div>
                            <h3 className="mb-2"><FormattedMessage id="accounting_feature_1" /></h3>
                            <p className={"text-large font-medium " + (tab !== 1 ? 'hidden' : '')}>
                                <FormattedMessage id="inventory_feature_desc_1" />
                            </p>
                        </div>
                    </div>
                </div> 
                {/* desktop */}
                <div className="flex flex-row py-24 hidden lg:flex">
                    <div className="basis-1/2 border-l border-t border-b p-10 rounded-l-3xl">
                        <ul className="flex flex-col gap-y-8">
                            <li className={"border-b cursor-pointer flex gap-x-4 " + (tab == 1 ? 'border-b-2 border-primary h-[158px]' : 'h-16 pb-8')} onClick={() => changeTab(1)}>
                                <div>
                                    <Icon icon="ic:round-local-shipping" width="32px" className="text-primary" />
                                </div>
                                <div>
                                    <h4 className="display-xs font-semibold pb-2">
                                        <FormattedMessage id="inventory_feature_title_1" />
                                    </h4>
                                    <p className={"text-large font-medium " + (tab !== 1 ? 'hidden' : '')}>
                                        <FormattedMessage id="inventory_feature_desc_1" />
                                    </p>
                                </div>
                            </li>
                            <li className={"border-b cursor-pointer flex gap-x-4 " + (tab == 2 ? 'border-b-2 border-primary h-[158px]' : 'h-16 pb-8')} onClick={() => changeTab(2)}>
                                <div>
                                    <Icon icon="ic:round-area-chart" width="32px" className="text-primary" />
                                </div>
                                <div>
                                    <h4 className="display-xs font-semibold pb-2">
                                        <FormattedMessage id="inventory_feature_title_2" />
                                    </h4>
                                    <p className={"text-large font-medium " + (tab !== 2 ? 'hidden' : '')}>
                                        <FormattedMessage id="inventory_feature_desc_2" />
                                    </p>
                                </div>
                            </li>
                            <li className={"border-b cursor-pointer flex gap-x-4 " + (tab == 3 ? 'border-b-2 border-primary h-[158px]' : 'h-16 pb-8')} onClick={() => changeTab(3)}>
                                <div>
                                    <Icon icon="ic:round-widgets" width="32px" className="text-primary" />
                                </div>
                                <div>
                                    <h4 className="display-xs font-semibold pb-2">
                                        <FormattedMessage id="inventory_feature_title_3" />
                                    </h4>
                                    <p className={"text-large font-medium " + (tab !== 3 ? 'hidden' : '')}>
                                        <FormattedMessage id="inventory_feature_desc_3" />
                                    </p>
                                </div>
                            </li>
                            <li className={"border-b cursor-pointer flex gap-x-4 " + (tab == 4 ? 'border-b-2 border-primary h-[158px]' : 'h-16 pb-8')} onClick={() => changeTab(4)}>
                                <div>
                                    <Icon icon="ic:round-manage-search" width="32px" className="text-primary" />
                                </div>
                                <div>
                                    <h4 className="display-xs font-semibold pb-2">
                                        <FormattedMessage id="inventory_feature_title_4" />
                                    </h4>
                                    <p className={"text-large font-medium " + (tab !== 4 ? 'hidden' : '')}>
                                        <FormattedMessage id="inventory_feature_desc_4" />
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* <div className={'basis-2/3 border-r border-t border-b p-10 bg-cover rounded-r-3xl ' + (tab == 1 ? 'bg-detail_pengiriman' : tab == 2 ? 'bg-forecast_penjualan' : tab == 3 ? 'bg-kelola_product' : 'bg-replenishment')}></div> */}
                    {/* */}
                    <div className="basis-1/2 border-r border-t border-b bg-cover rounded-r-3xl bg-[#F8FAFC] flex flex-row items-center justify-center">
                        <img src={tab == 1 ? replenishment : tab == 2 ? replenishment : tab == 3 ? replenishment : replenishment} alt="Product Image" className="h-full"/>
                    </div>
                </div>
                
            </section>
            <CTA_v2/>        
        </>
    );
}

export default Inventory;

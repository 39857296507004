import React from 'react';
import { Icon } from '@iconify-icon/react';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

function Footer () {
    return (
        <footer className='w-full bg-white'>
            <div className='grid md:grid-rows-1 lg:grid-cols-12 px-4 md:px-24 lg:px-24 gap-y-10 py-10 border'>
                <div className='flex flex-col gap-y-4 md:col-span-12 lg:col-span-4 w-full'>
                    <img alt="Logo" className='md:mx-auto lg:mx-0 mb-10' src={process.env.PUBLIC_URL + '/img/logo_dsn.svg'} style={{backgroundSize: 'cover', width: '120px'}}/>  
                    <div>
                        <h4 className='text-small font-semibold text-dark pb-1 md:text-center lg:text-left'>Main Office</h4>
                        <p className='text-medium md:text-center lg:text-left md:px-32 lg:px-0 leading-7'>Lippo ST. Moritz Tower Floor #09-01, Jl. Puri Raya Blok U1 , Puri Indah Kembangan Selatan </p>
                    </div>
                    <div>
                        <h4 className='text-small font-semibold text-dark pb-1 md:text-center lg:text-left'>Development Office</h4>
                        <p className='text-medium md:text-center lg:text-left md:px-32 lg:px-0 leading-7'>Jalan Kamal Raya Outer Ring Road, Mutiara Taman Palem A17 / 29 - 30, Kelurahan Cengkareng Timur, Kecamatan Cengkareng, Jakarta Barat, 11730</p>
                    </div>
                </div>
                <div className='md:col-span-12 lg:col-span-8'>
                    <ul className='flex flex-row justify-between md:justify-center lg:justify-end gap-y-8 gap-x-8 font-medium text-sm'>
                        <li>
                            <ul className='flex flex-col gap-y-4'>
                                <li className='text-large font-semibold text-dark'>Produk</li>
                                <li><Link to="/solution/accounting"><span className='text-medium font-medium'>Akuntansi</span></Link></li>
                                <li><Link to="/solution/crm"><span className='text-medium font-medium'>CRM</span></Link></li>
                                <li><Link to="/solution/inventory"><span className='text-medium font-medium'>Inventori</span></Link></li>
                                <li><Link to="/solution/manufacture"><span className='text-medium font-medium'>Manufaktur</span></Link></li>
                            </ul>
                        </li>
                        <li>
                            <ul className='flex flex-col gap-y-4'>
                                <li className='text-large font-semibold text-dark'>Perusahaan</li>
                                <li><Link to="/demo"><span className='text-medium font-medium'>Demo Gratis</span></Link></li>
                                <li><Link to="/contact"><span className='text-medium font-medium'>Hubungi Kami</span></Link></li>
                                <li><Link to="/casestudy"><span className='text-medium font-medium'>Studi Kasus</span></Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="px-4 md:px-24 lg:px-24 py-10 flex flex-col-reverse md:flex-col lg:flex-row gap-y-4 justify-between md:items-center lg:items-end">
                <p className='sm:text-left md:text-center text-medium font-medium visible md:hidden'>© 2024 Delta Solusi Nusantara,  All rights reserved.</p>
                <p className=' md:text-3xl md:w-3/4 lg:w-4/12 display-xs md:display-md font-semibold md:text-center lg:text-left'><FormattedMessage id="footer_tag_1" /><span className='text-red-500'>platform.</span></p>
                <div className='flex flex-col gap-y-6'>
                    <div className='flex gap-y-6 gap-x-8 flex-col md:flex-row'>
                        <div className='flex flex-row items-center gap-x-2'>
                            <div className='bg-primary text-white h-5 w-5 rounded flex items-center justify-center'>
                                <Icon icon="ic:round-call" width='15px' />
                            </div>
                            <p className='text-large font-semibold text-dark'>+62 (21) 5435 3008</p>
                        </div>
                        <div className='flex flex-row items-center gap-x-2'>
                            <div className='bg-primary text-white h-5 w-5 rounded flex items-center justify-center'>
                                <Icon icon="ic:round-call" width='15px' />
                            </div>
                            <p className='text-large font-semibold text-dark'>+62 813 8229 7335</p>
                        </div>
                    </div>
                    <p className='sm:text-left md:text-center text-medium font-medium hidden md:block'>© 2024 Delta Solusi Nusantara,  All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
import React from 'react';

import { StyledButton_v2 } from '../components/UI/Button_v2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

function NotFound404() {
    return (
        <>
            <Helmet>
                <title>404 - Delta Solusi Nusantara</title>
                <meta name="robots" content="noindex, nofollow"/>
            </Helmet>
            
            <main>
                <section className='border text-center px-5 py-10 lg:py-44'>
                    <h1 className='text-primary font-semibold text-5xl'>404</h1>
                    <h1 className='font-semibold text-4xl lg:text-5xl'>
                        <FormattedMessage id="not_found_text"/>
                    </h1>
                    <p className='mx-auto text-medium font-medium md:w-[468px] lg:w-[598px] mb-10'>                        
                        <FormattedMessage id="not_found_description"/>
                    </p>
                    <Link to="/">
                        <StyledButton_v2 type='primary' className='w-auto mx-auto'>
                            <FormattedMessage id="back_to_home_button"/>
                        </StyledButton_v2>
                    </Link>
                </section>
            </main>
            
        </>

    );
}

export default NotFound404;
import React from 'react';
import { Link } from "react-router-dom";
import { StyledButton_v2 } from '../UI/Button_v2';
import { FormattedMessage } from 'react-intl';
import cta_3 from '../../assets/cta/cta_3.jpg';

export const CTA_v1 = () => {
    return (
        <>
            <section className='pt-12 lg:pt-16'>
                <div className="px-4 lg:mx-auto lg:w-5/12 flex flex-col" >
                    <h1 className="text-center display-sm md:display-md lg:display-lg font-semibold mb-8" >
                        <FormattedMessage id="cta_tag" />
                    </h1>
                    <div className='mx-auto w-full md:w-auto mb-16'>
                        <Link to="/demo">
                            <StyledButton_v2 type='primary' className='w-full'>
                                <FormattedMessage id="demo_button" />
                            </StyledButton_v2>
                        </Link>
                    </div>
                </div>
                <div className='relative w-full '>
                    <div className='w-full md:w-3/5 lg:w-3/5 mx-auto relative overflow-hidden'>
                        <img alt="Hero Image" className='' src={process.env.PUBLIC_URL + '/img/desktop/Odoo Screen.png'}/>
                        <div className='bg-gradient-pattern absolute -bottom-20 h-full bg-top w-full'></div>
                    </div>
                    <img alt="Asset Image 5" className='hidden md:block lg:block absolute -top-28 right-28' src={process.env.PUBLIC_URL + '/img/desktop/asset_5.png'} style={{backgroundSize: 'cover', width: '20%',}}/>
                    <img alt="Asset Image 2" className='hidden md:block lg:block absolute bottom-14 left-36' src={process.env.PUBLIC_URL + '/img/desktop/asset_2.png'} style={{backgroundSize: 'cover', width: '20%',}}/>
                </div>
            </section>
        </>
    );
}

export const CTA_v2 = () => {
    return (
        <>
            <section className='px-4 pt-20 md:px-10 lg:px-32 py-16'>
                <div className='flex flex-col lg:flex-row drop-shadow-md border border-1 border-gray-200/50 relative rounded-3xl overflow-hidden'>
                    <div className='p-5 md:p-10 basis-2/5 flex gap-y-4 flex-col'>
                        <img alt="Logo" className='' src={process.env.PUBLIC_URL + '/img/logo_nnp.svg'} style={{backgroundSize: 'cover', width: '100px'}}/>
                        <h2 className='w-full display-xs md:display-md lg:display-md font-semibold text-left md:mb-11 lg:mb-8'>Nusa Network Prakasa mudah kelola perusahaan berkat DSN</h2>                        
                        <div>
                            <Link to="/casestudy" className='absolute md:static lg:static z-20 mb-16'>
                                <StyledButton_v2 type='primary'>Baca Studi Kasus</StyledButton_v2>
                            </Link>
                        </div>
                    </div>
                    {/* <img alt="Hero Image" className='relative left-10 lg:left-auto lg:absolute z-20 top-10 -right-[330px] w-[912px]' src={process.env.PUBLIC_URL + '/img/mobile/hero_image.svg'}/> */}
                    <img alt="Hero Image" className='relative lg:left-auto lg:absolute z-20 top-10 left-0 -right-[330px] w-[912px] ' src={process.env.PUBLIC_URL + '/img/mobile/hero_image.svg'}/>
                    <div className='bg-gradient-pattern absolute z-0 -right-20 -top-20 bg-top -rotate-[90deg] w-[600px] h-[700px] '></div>
                </div>
            </section>
        </>
    );
}

export const CTA_v3 = () => {
    return (
        <>
            <section className='pt-16 bg-blue-gray-50 mt-24'>
                <div className="px-4 lg:mx-auto lg:w-5/12 flex flex-col" >
                    <h1 className="text-center display-sm md:display-md lg:display-lg font-semibold" >
                        <FormattedMessage id="cta_tag" />
                    </h1>
                    <div className='mx-auto w-full md:w-auto mb-10'>
                        <Link to="/demo">
                            <StyledButton_v2 type='primary' className='w-full'>
                                <FormattedMessage id="demo_button" />
                            </StyledButton_v2>
                        </Link>
                    </div>
                </div>
                <div className='relative w-full border'>
                    <img src={cta_3} className='mx-auto'/>
                </div>
            </section>
        </>
    );
}
import React, {useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';

// import { Link } from "react-router-dom";
// import { StyledButton_v2 } from '../components/Button_v2';
import { CTA_v1, CTA_v2} from '../components/Blocks/CTA';
import { Header_v1 } from '../components/Blocks/Header';
import { StyledBadge } from '../components/UI/Badge';
import { StyledIcon } from '../components/UI/Icons';
import { useAppDispatch } from '../stateStore';
import { TrueFalseServices } from '../reducers/TrueFalseSlice';
// import { LocaleServices } from '../reducers/LocaleSlice';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
// import { IntlManager } from '../IntlManager';

function Landing() {
    const dispatch = useAppDispatch()
    
    // const currentLocale = useTypedSelector((state) => state.Locale.currentLocale)
    // const [locale, setLocale] = useState('en');
    const [searchParams] = useSearchParams();

    // const handleScrollDown = () => {
    //     window.scrollTo({
    //         top: 1600,
    //         left: 0,
    //         behavior: 'smooth'
    //     });
    // };


    useEffect(() => {
        dispatch(TrueFalseServices.actions.setToTrue())
        const scrollTop = searchParams.get('scrollTop');
        window.scrollTo({ top: scrollTop ? parseInt(scrollTop) : 0, left: 0 , behavior: 'smooth'});
    }, [dispatch, searchParams]);
    return (
        <>
            <Helmet>
                <title>Home - PT. Delta Solusi Nusantara</title>
                <meta name="description" content="Transform your business with Odoo ERP. A scalable solution to manage accounting, sales, inventory, and HR seamlessly. Start optimizing today!" />
                <meta name="keywords" content="Odoo ERP, business management software, ERP solution, accounting software, CRM software, inventory management, HR management, all-in-one ERP system, cloud ERP, open-source ERP" />

                {/* preload large content */}
                <link rel="preload" as="image" href={process.env.PUBLIC_URL + '/img/desktop/hero_image.png'} />
                <link rel="preload" as="image" href={process.env.PUBLIC_URL + '/img/mobile/mobile_hero.webp'} />
            </Helmet>

            <main className='w-full' >
                {/* <section className='bg-gradient-pattern bg-cover overflow-hidden'>
                    <div className="mx-4 md:mx-20 lg:mx-auto lg:w-[800px] flex flex-col" >

                        <h1 className="mt-20 md:mt-16 lg:mt-[76px] mb-4 md:mb-6 lg:mb-6 text-center font-semibold text-4xl md:text-6xl lg:text-7xl leading-[44px] md:leading-[72px] lg:leading-[5rem] -tracking-[2%] px-4">
                            <FormattedMessage id="tag_1" />
                        </h1>
                        <h3 className="mx-auto mb-8 md:mb-12 lg:mb-12 text-center text-medium md:text-extralarge lg:md:text-extralarge font-medium md:w-[468px] lg:w-[598px]">
                            <FormattedMessage id="tag_2" />
                        </h3>

                        <div className="flex flex-col md:flex-row justify-center gap-x-4 gap-y-4 mb-[83px] md:mb-16 lg:mb-16">
                            <Link to="/demo">
                                <StyledButton_v2 type='primary' className='w-full lg:min-w-fit'>
                                    <FormattedMessage id="demo_button" />
                                </StyledButton_v2>
                            </Link>
                            <div>
                                <StyledButton_v2 type='border' className='w-full lg:min-w-fit font-semibold' onClick={handleScrollDown}>
                                    <FormattedMessage id="learn_button" />
                                </StyledButton_v2>
                            </div>
                        </div>
                    </div>
                    <div className='border-b overflow-hidden relative' >

                        <img alt="Hero Image" src={process.env.PUBLIC_URL + '/img/desktop/hero_image.png'} className='w-[100%] relative -right-5 md:-right-10 lg:static lg:mx-auto lg:w-[80%] hidden md:block lg:block'/>
                        <img alt="Hero Image" src={process.env.PUBLIC_URL + '/img/mobile/mobile_hero.webp'} className='h-[344px] w-full object-fit relative left-5 top-5 block md:hidden lg:hidden'/>


                        <img alt="Asset Image" className='absolute md:bottom-6 lg:bottom-12 left-5 lg:left-20 hidden md:block lg:block shadow-lg rounded-2xl' src={process.env.PUBLIC_URL + '/img/desktop/asset_1.gif'} style={{backgroundSize: 'cover', width: '30%',}}/>
                    </div>
                </section> */}
                <Header_v1 tag_1="tag_1" tag_2="tag_2">
                    <img alt="Hero Image" src={process.env.PUBLIC_URL + '/img/desktop/hero_image.png'} className='w-[100%] relative -right-5 md:-right-10 lg:static lg:mx-auto lg:w-[80%] hidden md:block lg:block'/>
                    <img alt="Hero Image" src={process.env.PUBLIC_URL + '/img/mobile/mobile_hero.webp'} className='h-[344px] w-full object-fit relative left-5 top-5 block md:hidden lg:hidden'/>

                    <img alt="Asset Image" className='absolute md:bottom-6 lg:bottom-12 left-5 lg:left-20 hidden md:block lg:block shadow-lg rounded-2xl' src={process.env.PUBLIC_URL + '/img/desktop/asset_1.gif'} style={{backgroundSize: 'cover', width: '30%',}}/>
                </Header_v1>
                <section className='px-5 md:px-10 lg:px-20 py-16 flex flex-col gap-y-8'>
                    <h2 className='w-full md:w-3/4 lg:w-3/4 display-sm md:display-sm lg:display-md font-semibold mx-auto text-center mb-24'>
                        <FormattedMessage id="tag_3" />
                    </h2>

                    <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-6 grid-flow-row gap-y-8">
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo1/Frame 6923.svg'} style={{backgroundSize: 'cover', width: '100%', height: 'auto'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo1/Frame 6927.svg'} style={{backgroundSize: 'cover', width: '100%', height: 'auto'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo1/Frame 6933.svg'} style={{backgroundSize: 'cover', width: '100%', height: 'auto'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo1/Frame 6934.svg'} style={{backgroundSize: 'cover', width: '100%', height: 'auto'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo1/Frame 6936.svg'} style={{backgroundSize: 'cover', width: '100%', height: 'auto'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo1/Frame 6938.svg'} style={{backgroundSize: 'cover', width: '100%', height: 'auto'}}/>
                    </div>
                    <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-6 grid-flow-row gap-y-8">
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo2/Frame 6939.svg'} style={{backgroundSize: 'cover', width: '100%', height: 'auto'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo2/Frame 6940.svg'} style={{backgroundSize: 'cover', width: '100%', height: 'auto'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo2/Frame 6941.svg'} style={{backgroundSize: 'cover', width: '100%', height: 'auto'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo2/Frame 6942.svg'} style={{backgroundSize: 'cover', width: '100%', height: 'auto'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo2/Frame 6943.svg'} style={{backgroundSize: 'cover', width: '100%', height: 'auto'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo2/Frame 6944.svg'} style={{backgroundSize: 'cover', width: '100%', height: 'auto'}}/>
                    </div>
                </section>
                <section className='py-16'>
                    <div className="flex flex-col px-4 md:px-10 lg:px-28 gap-y-32">
                        <div className="flex flex-col-reverse lg:flex-row gap-x-20 gap-y-8">
                            <div className="w-full lg:w-1/2">
                                <StyledBadge type='primary' className='mb-4'><FormattedMessage id="product_section_1_badge" /></StyledBadge>
                                <h3 className="text-3xl font-semibold mb-2 text-black "><FormattedMessage id="product_section_1_title" /></h3>
                                <p className="text-medium mb-6"><FormattedMessage id="product_section_1_desc" /></p>
                                
                                <ul className="text-lg">
                                    <li className="flex gap-x-4 text-medium mb-6">
                                    <StyledIcon name="manufacture"/>
                                    Manufacture</li>
                                    <li className="flex gap-x-4 text-medium mb-6">
                                    <StyledIcon name="plm"/>
                                    Product Lifecycle Management
                                    </li>
                                    <li className="flex gap-x-4 text-medium mb-6">
                                    <StyledIcon name="purchase"/>
                                    Purchase
                                    </li>
                                    <li className="flex gap-x-4 text-medium mb-6">
                                    <StyledIcon name="maintenance"/>
                                    Maintenance
                                    </li>
                                </ul>
                            </div>
                            <div className="w-full lg:w-1/2 rounded-l-3xl relative overflow-hidden">
                                <img className='lg:absolute z-20 bottom-0 right-0' alt="Logo Image" src={process.env.PUBLIC_URL + '/img/product/ProductImage.webp'} style={{backgroundSize: 'cover', width: '95%'}}/>
                            </div>
                        </div>
                        <div className="flex flex-col-reverse lg:flex-row-reverse gap-x-20 gap-y-8">
                            <div className="w-full lg:w-1/2">
                                <StyledBadge type='primary' className='mb-4'><FormattedMessage id="product_section_2_badge" /></StyledBadge>
                                <h3 className="text-3xl font-semibold mb-2 text-black"><FormattedMessage id="product_section_2_title" /></h3>
                                <p className="text-medium mb-6"><FormattedMessage id="product_section_2_desc" /></p>
                                
                                <ul className="text-lg">
                                    <li className="flex gap-x-4 text-medium mb-6">
                                    <StyledIcon name="plm"/>
                                    Accounting</li>
                                    <li className="flex gap-x-4 text-medium mb-6">
                                    <StyledIcon name="faktur"/>
                                    Invoices
                                    </li>
                                    <li className="flex gap-x-4 text-medium mb-6">
                                    <StyledIcon name="pengeluaran"/>
                                    Expenses
                                    </li>
                                    <li className="flex gap-x-4 text-medium mb-6">
                                    <StyledIcon name="dokumen"/>
                                    Documents
                                    </li>
                                </ul>
                            </div>
                            <div className="w-full lg:w-1/2 rounded-r-3xl relative overflow-hidden">
                                <img className='lg:absolute z-20 bottom-0 left-0' alt="Logo Image" src={process.env.PUBLIC_URL + '/img/product/ProductImage1.webp'} style={{backgroundSize: 'cover', width: '95%'}}/>
                                {/* <div className='bg-gradient-pattern absolute z-0 -left-18 -bottom-32 bg-top -rotate-[70deg] w-[600px] h-[700px] '></div> */}
                            </div>
                        </div>
                        <div className="flex flex-col-reverse lg:flex-row gap-x-20 gap-y-8">
                            <div className="w-full lg:w-1/2">
                            {/* <div className="px-5 py-3 w-min text-nowrap rounded-lg bg-red-100/50 text-red-500 mb-4">Sales</div> */}
                            <StyledBadge type='primary' className='mb-4'><FormattedMessage id="product_section_3_badge" /></StyledBadge>
                            <h3 className="text-3xl font-semibold mb-2 text-black"><FormattedMessage id="product_section_3_title" /></h3>
                            <p className="text-medium mb-6"><FormattedMessage id="product_section_3_desc" /></p>
                            
                            <ul className="text-lg">
                                <li className="flex gap-x-4 text-medium mb-6">
                                <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/icon/crm.svg'} style={{backgroundSize: 'cover'}}/>
                                CRM</li>
                                <li className="flex gap-x-4 text-medium mb-6">
                                <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/icon/sales.svg'} style={{backgroundSize: 'cover'}}/>
                                Sales
                                </li>
                                <li className="flex gap-x-4 text-medium mb-6">
                                <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/icon/ps.svg'} style={{backgroundSize: 'cover'}}/>
                                Point of Sales
                                </li>
                                <li className="flex gap-x-4 text-medium mb-6">
                                <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/icon/langganan.svg'} style={{backgroundSize: 'cover'}}/>
                                Subsriptions
                                </li>
                            </ul>
                            </div>
                            <div className="w-full lg:w-1/2 rounded-l-3xl relative overflow-hidden">
                                <img className='lg:absolute z-20 bottom-0 right-0' alt="Logo Image" src={process.env.PUBLIC_URL + '/img/product/ProductImage2.webp'} style={{backgroundSize: 'cover', width: '95%'}}/>
                            </div>
                        </div>
                    </div>
                </section>
                <CTA_v2/>
                <CTA_v1/>
            </main>
        </>

    );
}
export default Landing
